import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Head from "../components/head"
import Img from "gatsby-image"

export default function DashboardSummary() {
  // const data = useStaticQuery(graphql`
  //   query Image {
  //     image: file(relativePath: { eq: "myself/myself1_burned.png" }) {
  //       id
  //       childImageSharp {
  //         fixed(width: 300) {
  //           ...GatsbyImageSharpFixed
  //         }
  //         fluid {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <section id="/" className="dashboard__container">
      <Head />
      <div className="dashboard__box">
        <div className="dashboard__top">
          <p className="sub__header">Hi, my name is</p>
          <h1 className="dashboard__name">Vincent Tern</h1>
          <p>I'm a Web Developer</p>
        </div>

        {/**
        
          <div className="dashboard__bottom">
            <p className="dashboard__workType">
              I am actively seeking new opportunities in these job types:
            </p>
            <ul className="workType">
              <li className="workType--btn">Full-Time</li>
              <li className="workType--btn">Contract</li>
              <li className="workType--btn">Part-Time</li>
              <li className="workType--btn">Remote</li>
            </ul>
            <p className="dashboard__workAvailable">Need a developer?</p>
            <Link className="connectInTouch btn" to="/#contact">
              Get In Touch
            </Link>
          </div>
        */}
      </div>

      {/**
      
        <div className="dashboard__box">
          <div className="image__box">
            <Img
              className="profile__image"
              fixed={data.image.childImageSharp.fixed}
              alt="headshot"
            />
          </div>
        </div>
      
       */}
    </section>
  )
}
